<template>
	<div>
		<CRow>
			<CCol>
				<CCard>
					<CCardHeader>
						Permission List
					</CCardHeader>
					<CCardBody>
						<CRow>
							<CCol sm="2">
								<label class=""> &nbsp; </label>
								<CButton class="" block @click="getPermission" color="info"
									><CIcon name="cil-reload"
								/></CButton>
							</CCol>
							<CCol sm="3">
								<div role="group" class="form-group">
									<label class=""> Role </label>
									<select
										@change="getPermission"
										v-model="role"
										class="form-control"
									>
										<option v-for="r in roles" :key="r.code" :value="r.code">
											{{ r.name }}
										</option>
									</select>
								</div>
							</CCol>
						</CRow>

						<CRow>
							<div
								class="position-relative table-responsive col-sm-6 tableFixHead"
							>
								<table class="table">
									<thead class="thead-dark">
										<tr>
											<th>ROLE</th>
											<th>CODE</th>
											<th>ENTER</th>
										</tr>
									</thead>
									<tbody class="position-relative">
										<tr v-for="(permission, i) in permissions" :key="i">
											<td>{{ permission.roleId }}</td>
											<td>{{ permission.access.code }}</td>
											<td>
												<label
													class="mx-1 c-switch form-check-label c-switch-square c-switch-3d-primary c-switch-label"
												>
													<input
														v-on:change="updateEnter(i)"
														:checked="permission.enter"
														type="checkbox"
														class="c-switch-input form-check-input"
													/>
													<span
														data-checked="✓"
														data-unchecked="✕"
														class="c-switch-slider"
													></span>
												</label>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</CRow>
					</CCardBody>
				</CCard>
			</CCol>
		</CRow>
	</div>
</template>

<script>
export default {
	data() {
		return {
			permissions: [],
			role: "admin",
			roles: [],
		};
	},
	mounted() {
		this.getPermission();
		this.getRole();
	},
	methods: {
		async getPermission() {
			const { data } = await this.$http.get("acl/permission", {
				params: { role: this.role },
			});

			this.permissions = data.data;
		},
		async getRole() {
			this.roles = [];
			const { data } = await this.$http.get("acl/role");

			this.roles = data.data;
		},
		async updateEnter(i) {
			const enter = !this.permissions[i].enter;
			const id = this.permissions[i].id;
			try {
				let datar = {};
				const { data } = await this.$http.put(
					"acl/permission",
					{ enter: enter },
					{ params: { id: id } }
				);
				datar = data;
				if (datar.status == "success") {
					this.$toast.success("Success ! " + datar.message);
					this.modal = false;
				} else {
					this.$swal("Server Fail !", datar.message, "danger");
				}
			} catch (error) {
				this.$swal("Error !", error.message, "danger");
			}
		},
	},
};
</script>

<style lang="css" scoped>
.tableFixHead {
	overflow: auto;
	height: 500px;
}
.tableFixHead thead th {
	position: sticky;
	top: 0;
	z-index: 100;
}
</style>
